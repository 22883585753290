import { fetchUrl } from "../api/generic_fetch"

export default async (): Promise<Flags | null> => {
  let userData

  try {
    userData = await fetchUrl("/api/user-data.json") as Flags
  } catch {
    userData = null
  }

  return userData
}
