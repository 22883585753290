import { BREAKPOINTS as SCREEN_BREAKPOINTS } from "../const/breakpoints"

const screenMaxWidth = (breakpoint : number) : boolean => {
  return window.matchMedia(`screen and (max-width: ${breakpoint}px)`).matches
}

const screenMinWidth = (breakpoint : number) : boolean => {
  return window.matchMedia(`screen and (min-width: ${breakpoint + 1}px)`).matches
}

export const isSmallPalm : () => boolean = () =>
  screenMaxWidth(SCREEN_BREAKPOINTS.smallPalm)

export const isPalm : () => boolean = () =>
  screenMinWidth(SCREEN_BREAKPOINTS.smallPalm) &&
    screenMaxWidth(SCREEN_BREAKPOINTS.mobile)

export const isMobile : () => boolean = () =>
  screenMaxWidth(SCREEN_BREAKPOINTS.mobile)

export const isTab : () => boolean = () =>
  screenMinWidth(SCREEN_BREAKPOINTS.mobile) &&
    screenMaxWidth(SCREEN_BREAKPOINTS.handheld)

export const isTabletPortrait : () => boolean = () =>
  screenMaxWidth(SCREEN_BREAKPOINTS.tabletPortrait)

export const isHandheld : () => boolean = () =>
  screenMaxWidth(SCREEN_BREAKPOINTS.handheld)

export const isLap : () => boolean = () =>
  screenMinWidth(SCREEN_BREAKPOINTS.handheld) &&
    screenMaxWidth(SCREEN_BREAKPOINTS.portable)

export const isDesktop : () => boolean = () =>
  screenMinWidth(SCREEN_BREAKPOINTS.portable)

export const toString : () => string = () => {
  if (isDesktop()) {
    return "desktop"
  } else if (isLap()) {
    return "laptop"
  } else if (isTab()) {
    return "tablet"
  } else if (isMobile()) {
    return "mobile"
  } else if (isSmallPalm()) {
    return "small-palm"
  } else {
    return ""
  }
}
