import { IS_PRODUCTION } from "./environment"

declare global {
  interface Window {
    dataLayer: object[]
  }
}

const GA4_PREFIX = "ga4_"

// app/helpers/gtm/ga4_helper.rb
// webpack/src/components/elm/Ga4/Main.elm
const BOOKINGS_CONTENT_GROUP = "Booking"
const VOUCHERS_CONTENT_GROUP = "Voucher Checkout"

type EventParams = {
  [key: string]: string | number | boolean | object
}

type EcommerceParams = EventParams & {
  currency?: string
  value?: number
  items: EcommerceItem[]
  optionalParams?: EventParams
}

type Event = EventParams & {
  event: string
}

type EcommerceEvent = EcommerceParams & {
  event: string
}

export type GA4Event = (Event | EcommerceEvent) & {
  event_type: "GA4Event"
}

type StringifiedEvent = string

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#purchase_item
type EcommerceItem = {
  coupon?: string,
  discount?: number,
  index?: number,
  item_brand?: string
  item_category: string,
  item_category2?: string,
  item_category3?: string,
  item_category5?: string,
  item_id?: string,
  item_list_name?: string,
  item_name: string,
  item_variant?: string,
  price: number,
  quantity: number
}

const tryParseJSON = (json: Event | StringifiedEvent): object => {
  try {
    return JSON.parse(<StringifiedEvent>json)
  } catch {
    return <object>json
  }
}

const pushGa4Event = (event: Event | StringifiedEvent): void => {
  const payload = <GA4Event>{
    ...tryParseJSON(event),
    event_type: "GA4Event"
  }
  window.dataLayer = window.dataLayer || []

  // As recommended per docs, nullify any previous `ecommerce` value before pushing to avoid
  // potentially conflicting ecommerce data due to recursive merges.
  if (payload.ecommerce) {
    window.dataLayer.push({ ecommerce: null })
  }

  window.dataLayer.push(payload)

  if (!IS_PRODUCTION) {
    console.log("[GA4 Event]", payload) // eslint-disable-line no-console
  }

  // Nullify `event_type` to prevent recursive merge issues on successive data layer
  // pushes from other sources. E.g. a third-party push to the data layer could
  // inadvertently trigger GA4 tags in GTM if this param is still present.
  window.dataLayer.push({ event_type: null })
}

const saveGa4ToStorage = ([key, value]: [string, string]): void => {
  window.sessionStorage.setItem(`${GA4_PREFIX}${key}`, value)
}

const pushGa4StorageToDataLayer = (): void => {
  window.dataLayer = window.dataLayer || []

  for (let i = 0, size = localStorage.length; i < size; i++) {
    const key = window.sessionStorage.key(i)

    if (key?.startsWith(GA4_PREFIX)) {
      window.dataLayer.push({ [key.slice(GA4_PREFIX.length)]: window.sessionStorage.getItem(key) })
      window.sessionStorage.removeItem(key)
    }
  }
}

const filteredNavigationType = (): void => {
  saveGa4ToStorage(["navigation_type", "filtered"])
}

const pushEcommerceEvent = (ecommerce: EcommerceEvent): void => {
  pushGa4Event({
    event: ecommerce.event,
    ecommerce: {
      items: ecommerce.items,
      currency: "GBP",
      value: ecommerce.value,
      ...ecommerce.optionalParams
    }
  })
}

const addToCartEvent = (item: EcommerceItem, value: number): void => {
  pushEcommerceEvent({ event: "add_to_cart", items: [item], value })
}

const selectContentEvent = (contentType: string, listName: string, itemName: string): void => {
  pushGa4Event({
    event: "select_content",
    content_type: contentType,
    item_list_name: listName,
    item_name: itemName
  })
}

const viewItemListEvent = (listName: string, items: EcommerceItem[]): void => {
  pushEcommerceEvent({ event: "view_item_list", item_list_name: listName, items })
}

const viewSearchResultsEvent = (): void => {
  const filters = document.querySelector("[data-filter-count]") as HTMLElement
  const { date, lengthsOfStay, location } = window.elmSearchFlags
  let selectedDate = "Any date"

  if (date.flexibility) {
    switch (date.flexibility.value) {
    case 1:
      selectedDate = "Flexible date"
      break
    case 2:
      selectedDate = "Weekdays"
      break
    case 3:
      selectedDate = "Weekends"
      break
    default:
      selectedDate = "Specific date"
    }
  }

  pushGa4Event({
    event: "search",
    search_term: location.selected?.name || "",
    search_url: window.location.href.replace(window.location.origin, ""),
    q_date: selectedDate,
    q_length_of_stay: lengthsOfStay.selected[1],
    filter_count: filters?.dataset?.filterCount || 0
  })
}

const addEventAttributeListener = (): void => {
  // Regular click events are handled via GTM because they have well-defined event
  // parameters, but other custom events can have any number (and name) of params so we
  // have to parse and transform them here.
  // For any such event to be recorded in GA4 we must ensure ONE of the following:
  // 1. All custom params are added to the "Custom Event" tag in GTM, or
  // 2. A tag/trigger combo is created in GTM specifically for the event
  document.addEventListener("click", e => {
    const ga4Element = <HTMLElement>(e.target as HTMLElement).closest("[data-ga4-event]")
    if (!ga4Element) return

    const eventParams = Object.entries(ga4Element.dataset).reduce((prev, [attr, val]) => {
      const isGa4Attribute = attr.slice(0, 3) === "ga4"

      if (isGa4Attribute && val) {
        const snakeCaseParam = attr.slice(3).split(/(?=[A-Z])/).join("_").toLowerCase()

        return { ...prev, [snakeCaseParam]: tryParseJSON(val) }
      } else {
        return prev
      }
    }, {})

    pushGa4Event(<Event>eventParams)
  })
}

const addStorageAttributeListener = (): void => {
  document.addEventListener("click", e => {
    const ga4Element = <HTMLElement>(e.target as HTMLElement).closest("[data-ga4-storage]")
    const data = ga4Element?.dataset["ga4Storage"]
    if (!data) return

    // Attribute is of the form: `data-ga4-storage="key:value"`
    saveGa4ToStorage(data.split(":") as [string, string])
  })
}

const setupGa4 = (): void => {
  addEventAttributeListener()
  addStorageAttributeListener()
  pushGa4StorageToDataLayer()
}

export {
  type EcommerceEvent,
  BOOKINGS_CONTENT_GROUP,
  VOUCHERS_CONTENT_GROUP,
  addToCartEvent,
  filteredNavigationType,
  pushGa4Event,
  pushGa4StorageToDataLayer,
  saveGa4ToStorage,
  selectContentEvent,
  setupGa4,
  viewItemListEvent,
  viewSearchResultsEvent
}
