import "../../styles/ui_components/_accordion.scss"

import { isHandheld } from "../../helpers/window_size"

const ACCORDION_SELECTOR = ".ui-component-accordion"

class Accordion {
  private static ACTIVE_CLASS = "--active"
  private static ITEM_SELECTOR = ".accordion-item"
  private static READY_ATTR = "data-ready"
  private static TOGGLE_ALL_ACTIONS = Object.freeze({
    COLLAPSE: "collapse",
    EXPAND: "expand"
  } as const)
  private static TOGGLE_ALL_SELECTOR = ".js-toggle-accordion"

  private element: HTMLElement

  constructor(element: HTMLElement) {
    this.element = element

    if (!this.element.hasAttribute(Accordion.READY_ATTR)) {
      this.element.addEventListener("click", this.setActiveItem.bind(this))

      document.querySelectorAll(Accordion.TOGGLE_ALL_SELECTOR)
        .forEach(toggle => toggle.addEventListener("click", this.onToggleAll.bind(this)))

      this.element.setAttribute(Accordion.READY_ATTR, "true")
    }
  }

  collapseAll(): void {
    this.items.forEach(Accordion.collapse)
  }

  expandAll(): void {
    this.items.forEach(Accordion.expand)
  }

  onToggleAll(e: Event): void {
    const toggle = e.target as HTMLElement
    const { action, target } = toggle.dataset

    if (this.element.id === target) {
      e.preventDefault()

      if (action === Accordion.TOGGLE_ALL_ACTIONS.EXPAND) {
        this.expandAll()
      } else if (action === Accordion.TOGGLE_ALL_ACTIONS.COLLAPSE)  {
        this.collapseAll()
      }
    }
  }

  setActiveItem(e: Event): void {
    const target = e.target as HTMLElement
    const childTarget = target.closest("a, button")
    const summaryTarget = target.closest(`${Accordion.ITEM_SELECTOR} > :first-child`)
    const selectedItem = summaryTarget?.parentNode as HTMLElement

    // Ignore link/button clicks in summary, and any clicks in description
    if (childTarget || !selectedItem) return

    if ("autoCollapse" in this.element.dataset) {
      [...this.items].filter(item => item !== selectedItem).forEach(Accordion.collapse)

      if (isHandheld()) {
        selectedItem.scrollIntoView()
      }
    }

    Accordion.toggle(selectedItem)
  }

  private static collapse(item: HTMLElement): void {
    item.classList.remove(Accordion.ACTIVE_CLASS)
  }

  private static expand(item: HTMLElement): void {
    item.classList.add(Accordion.ACTIVE_CLASS)
  }

  private static toggle(item: HTMLElement): void {
    item.classList.toggle(Accordion.ACTIVE_CLASS)
  }

  private get items(): NodeListOf<HTMLElement> {
    return this.element.querySelectorAll(Accordion.ITEM_SELECTOR)
  }
}

const setupAccordions = (): Accordion[] => {
  const createAccordions = (): Accordion[] =>
    [...document.querySelectorAll<HTMLElement>(ACCORDION_SELECTOR)]
      .map(accordion => new Accordion(accordion))

  const accordionObserver = new window.MutationObserver(mutuations => {
    mutuations.forEach(mutation => {
      if (mutation.type === "childList") {
        createAccordions()
      }
    })
  })

  accordionObserver.observe(document.body, { subtree: true, childList: true })

  return createAccordions()
}

export { setupAccordions }
